import axios from 'axios'
import https from 'https'

import { flagError } from 'error-handling'

import mCommerce from './m-commerce'
import eCommerce from './e-commerce'
import checkStatus from './checkStatus'

const SWISH_PRODUCTION = 'https://cpc.getswish.net/swish-cpcapi/api'
const SWISH_STAGING = 'https://staging.getswish.pub.tds.tieto.com/cpc-swish/api'
const SWISH_SIMULATOR = 'https://mss.cpc.getswish.net/swish-cpcapi/api'
const QR_CODE = 'https://mpc.getswish.net/qrg-swish/api'

export type PayObject = {
  payeePaymentReference: string
  payerAlias?: string
  amount: string
  message: string
  currency: string
  callbackUrl: string
}

export type SwishConfig = {
  payeeAlias: string
}

export enum SWISH_ENVIRONMENT {
  dev = 'dev',
  stage = 'stage',
  production = 'production',
}

export enum PAYMENT_STATUS {
  ERROR = 'ERROR',
  CREATED = 'CREATED',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
}

export const ERROR_STATUSES = [
  PAYMENT_STATUS.CANCELLED,
  PAYMENT_STATUS.DECLINED,
  PAYMENT_STATUS.ERROR,
]

interface IInitializeSwish {
  environment: SWISH_ENVIRONMENT
  agent: https.Agent
  config: SwishConfig
}

export default function initializeSwish({
  environment,
  agent,
  config,
}: IInitializeSwish) {
  const baseURL = {
    [SWISH_ENVIRONMENT.dev]: SWISH_SIMULATOR,
    [SWISH_ENVIRONMENT.stage]: SWISH_STAGING,
    [SWISH_ENVIRONMENT.production]: SWISH_PRODUCTION,
  }[environment]

  const client = axios.create({
    httpsAgent: agent,
  })

  const createPaymentRequest = async (id: string, data: PayObject) => {
    try {
      // https://developer.swish.nu/api/payment-request/v2
      const response = await client.put(`${baseURL}/v2/paymentrequests/${id}`, {
        ...data,
        ...config,
      })
      return {
        status: response.status,
        token: response.headers['paymentrequesttoken'],
      }
    } catch (err) {
      flagError(err, 'swish/createPaymentRequest')
    }
  }

  const createRefund = async (id: string, data: PayObject) => {
    // https://developer.swish.nu/api/refunds/v2
    return await client.put(`${baseURL}/v2/refunds/${id}`, data)
  }

  const createQrCode = async (token: string) => {
    try {
      // https://developer.swish.nu/api/qr-codes/v1
      const resp = await fetch(`${QR_CODE}/v1/commerce`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          format: 'svg',
        }),
      })
      const blob = await resp.blob()
      const buffer = Buffer.from(await blob.text())
      return 'data:' + blob.type + ';base64,' + buffer.toString('base64')
    } catch (err) {
      flagError(err, 'swish/qr-code')
    }
  }

  const getPaymentRequest = async (id: string) => {
    try {
      console.log('getPaymentRequest url:', `${baseURL}/v1/paymentrequests/${id}`)
      const response = await client.get(`${baseURL}/v1/paymentrequests/${id}`)

      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      flagError(error, 'swish/checkStatus')
      return error
    }
  }

  return {
    createPaymentRequest,
    createRefund,
    createQrCode,
    getPaymentRequest,
  }
}

export { mCommerce, eCommerce, checkStatus }
