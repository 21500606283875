<template>
  <component :is="forms[formComponentId]" />
</template>

<script setup>
import MembershipMonthlyOrDonation from './MembershipMonthlyOrDonation.vue';
import MembershipMonthly from './MembershipMonthly.vue';
import DonationStandard from './DonationStandard.vue';
import DonationSwish from './DonationSwish.vue';

const forms = {
  component_id_1: MembershipMonthlyOrDonation, // TODO: Remove
  membership_monthly: MembershipMonthly,
  membership_monthly_or_donation: MembershipMonthlyOrDonation,
  donation: DonationStandard,
  swish_number: DonationSwish,
};

defineProps({
  formComponentId: String,
});
</script>
