import { ZodError } from 'zod'
import superjson from 'superjson'
import Rollbar from 'rollbar'

export type Err = ZodError | Error | unknown

function rollbar(): Rollbar | null {
  return process?.env?.ROLLBAR_TOKEN_SERVER
    ? new Rollbar({
      accessToken: process?.env?.ROLLBAR_TOKEN_SERVER,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process?.env?.ENVIRONMENT,
      },
    })
    : null
}

export const flagError = (
  e: Err,
  context: string,
  externalLogger: Rollbar | null = rollbar(),
): { message: string; errors: string } => {
  const message = `Error in [${context}]`
  const errors =
    e instanceof ZodError
      ? superjson.stringify(e.flatten())
      : e instanceof Error
        ? e.message
        : 'Unknown error'

  console.error(message + ' -- ' + errors?.message || '', errors)
  externalLogger?.error(message, errors)
  return { message, errors }
}

export const flagLog = (
  message: any,
  externalLogger: Rollbar | null = rollbar(),
): void => {
  externalLogger?.log(message)
  console.log(message)
}
