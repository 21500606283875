import crypto from 'crypto'
import { z } from 'zod'
import { flagError } from 'error-handling'
import type { ApiResponse } from './types'
import initializeSwish from '.'

const schemaEcommerceEvent = z.object({
  payerAlias: z.string(),
  payeePaymentReference: z.string(),
  amount: z.string(),
  message: z.string().optional(),
  currency: z.string().optional(),
  callbackUrl: z.string(),
})

export type EcommerceRequest = z.infer<typeof schemaEcommerceEvent>

export interface ApiCreatePayment extends ApiResponse {
  operationId: string
  paymentRequestToken: string
  qr: string
  swishAppUrl: string
}

interface IECommerce {
  config: any
  paymentData: any
}

async function eCommerce({ config, paymentData }: IECommerce): Promise<undefined | any> {
  try {
    const {
      payeePaymentReference,
      payerAlias,
      amount = '100',
      currency = 'SEK',
      message = 'Donation',
      callbackUrl = '',
    }: EcommerceRequest = paymentData

    const uuid = crypto.randomUUID().replace(/\-/g, '').toUpperCase()

    const swish = initializeSwish(config)

    const dataECommerce = {
      payeePaymentReference,
      payerAlias,
      callbackUrl,
      amount,
      currency,
      message,
    }

    const responseECommerce = await swish.createPaymentRequest(uuid, dataECommerce)

    return {
      operationId: uuid,
      message:
        Number(responseECommerce?.status) === 201
          ? 'Check Swish Application to complete transaction'
          : 'Something went wrong',
      errors: 'Something went wrong',
    }
  } catch (e) {
    flagError(e, 'e-commerce')
    return undefined
  }
}

export default eCommerce
