<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="relative flex flex-1 flex-col">
    <label
      class="mb-1.5 inline-block font-sans text-base text-black lg:mb-3 lg:text-lg lg:leading-snug"
      :class="styleLabel"
      :for="id"
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <div class="relative">
      <input
        :id="id"
        v-model="numericInput"
        class="max-h-[60px] flex-1 rounded-md border-transparent p-3 leading-none focus:border-transparent focus:ring-0 lg:p-4 lg:text-xl lg:leading-none"
        type="text"
        :class="[styleInput, v$?.$invalid && v$?.$dirty && '!border-pink']"
        :placeholder="placeholder"
        :required="required"
        :min="min"
        :max="max"
        :name="name"
        :hidden="hidden"
        :disabled="disabled"
        :read-only="readOnly"
        @input="e => update(e, name)"
        @blur="v$ && v$.$validate()"
      />
      <div
        v-if="invalidMessage"
        class="absolute mt-0.5 flex w-full items-center rounded px-1 py-0 pr-3 font-sans text-sm text-pink"
        :class="styleError"
      >
        {{ invalidMessage }}
      </div>
      <div
        v-if="v$?.$invalid && v$?.$dirty && v$?.$errors?.length > 0"
        class="absolute mt-0.5 flex w-full items-center rounded px-1 py-0 pr-3 font-sans text-sm text-pink"
        :class="styleError"
        role="alert"
      >
        <span v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</span>
      </div>

      <div v-if="right" v-html="right"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { watchDebounced } from '@vueuse/core'
const id = uuidv4()

const props = defineProps({
  label: String,
  styleInput: String,
  styleLabel: String,
  styleError: String,
  pattern: { type: String, default: '' },
  patternError: { type: String, default: '' },
  name: String,
  type: {
    type: String,
    default: 'text',
  },
  required: { type: Boolean, default: false },
  placeholder: String,
  modelValue: [String, Number],
  right: String,
  min: String,
  max: String,
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
  v$: {
    type: Object,
    default: undefined,
  },
})

const numericInput = ref(props.modelValue)

const emit = defineEmits(['update:modelValue', 'setData'])

watch(
  () => props.modelValue,
  () => (numericInput.value = props.modelValue),
)

// TODO: Simplify this debounced validation
const invalidMessage = ref('')

const checkPattern = () => {
  if (!props.pattern) invalidMessage.value = ''
  const regex = new RegExp(props.pattern)
  return regex.test(numericInput.value)
    ? (invalidMessage.value = '')
    : (invalidMessage.value = props.patternError)
}

watchDebounced(
  numericInput,
  () => {
    checkPattern()
  },
  { debounce: 1000, maxWait: 5000 },
)

const update = (e: any, key: any) => {
  if (props.type === 'number' && typeof numericInput?.value?.replace === 'function') {
    numericInput.value = numericInput.value.replace(/[^0-9]/g, '')
  }
  // console.log('emitting setData, ', { key, value: numericInput.value });
  emit('setData', { key, value: numericInput.value })
  // console.log('emitting update:modelValue, ', numericInput.value);
  emit('update:modelValue', numericInput.value)
}

onMounted(() => {
  nextTick(() => {
    update(null, props.name)
  })
})
</script>
