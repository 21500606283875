import { useRuntimeConfig, useRouter, useFetch } from '#app';
import { flagError } from 'error-handling';

// NOTE: This can't be moved to a composable since it depends on the useFetch performance optimizations
// TODO: Create a separate version for refresh-common

export const useGetResource = async route => {
  const router = useRouter();
  const config = useRuntimeConfig();
  // console.log('router getResource', route);

  const { data, error } = await useFetch(
    '/api/content?route=' + route.path + '&siteLang=' + config.public.siteLang,
  );

  if (error.value?.data) {
    flagError(error.value?.data, 'useJsonApi/useGetResource');
    // return router.push({ path: '/404' });
    return { redirect: '/404' };
  }

  if (data?.value?.resolved) {
    // console.log('resolved', data.value.resolved);
  }

  if (data?.value?.redirect) {
    // console.log('redirect', data.value.redirect);
    const redirectData = data?.value?.redirect.find(item => item.from === route.path);
    // console.log('redirect to', redirectData.to);

    // Local redirect
    if (!redirectData.to.includes('http')) {
      router.push({ path: redirectData.to, query: route.query, hash: route.hash });
    }

    // TODO: There must be a better way to do this
    // Defer external redirect
    return { redirect: redirectData.to };
  }

  if (!data.value) {
    // return router.push({ path: '/404' });
    return { redirect: '/404' };
  }
  return data.value;
};

export const useGetData = async () => {
  const config = useRuntimeConfig();
  const lang = config.public.siteLang ? config.public.siteLang : 'sv';
  const { data, error } = await useFetch('/api/static');

  if (error.value?.data) {
    flagError(error.value?.data, 'useJsonApi/useGetData');
  }

  return {
    additionalData: data?.value[0]?.data[lang],
    footerData: data?.value[1]?.data[lang],
    primaryNav: data?.value[2],
  };
};
